.user-view-btn {
  display: flex;
  justify-content: center;
  padding: 4px;
}



/* ========== Custom Styles Whitelist Modal =========== */

.add-modal .text-field .MuiFormLabel-root.Mui-focused {
  color: #000000;
}

.add-modal .text-field .MuiInput-underline:after,
.add-modal .text-field .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #000000;
}

.main-modal .modal-content .modal-body {
  padding: 20px !important;
}

.form-group input[type=file] {
  opacity: 1 !important;
  position: relative !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #000000;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.text {
  color: #fff !important;
  font-size: 14px;
  margin-top: 10px;
}

.buttons {
  display: flex;
  margin: auto;
}

/* CSS for expanding textarea */
.textarea-container {
  position: relative;
}

.textarea-expanding {
  min-height: 40px;
  resize: none;
  overflow: hidden;
  transition: height 0.2s ease;
}

.btn-primary {
  background-color: rgba(0, 0, 0, 0.08);
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 11px 40px;
  font-size: 0.875rem;
  line-height: 1.35em;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-edit:hover,
.btn-edit:focus {
  text-decoration: none;
}

.btn-edit:focus,
.btn-edit.focus {
  outline: 0;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.MuiButton-root {
  color: red;
  background-color: black;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
}