
/* Animations */
.nav-pills .nav-link,
.navbar,
.nav-tabs .nav-link,
.sidebar .nav a,
.sidebar .nav a i,
.sidebar .nav p,
.navbar-collapse .navbar-nav .nav-link,
.animation-transition-general,
.tag,
.tag [data-role="remove"],
.animation-transition-general{
    @include transitions($general-transition-time, $transition-ease);
}

//transition for dropdown caret
.bootstrap-switch-label:before,
.caret{
    @include transitions($fast-transition-time, $transition-ease);
}

.dropdown-toggle[aria-expanded="true"]:after,
a[data-toggle="collapse"][aria-expanded="true"] .caret,
.card-collapse .card a[data-toggle="collapse"][aria-expanded="true"] i,
.card-collapse .card a[data-toggle="collapse"].expanded i{
    @include rotate-180();
}

.caret{
  width: 0;
  height: 0;
  vertical-align: middle;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  margin-top: -5px;
  position: absolute;
  top: 30px;
  margin-left: 5px;
}

.pull-left{
  float: left;
}
.pull-right{
  float: right;
}


// card user profile page

.card {
  form {
    label + .form-control {
      margin-bottom: 20px;
    }
  }
}

.card {
  .map-title {
    color: $white;
  }

  &.card-chart {
    .gmnoprint,
    .gm-style-cc {
      display: none !important;
    }
  }
}

// documentation

.bd-docs {

  h1,h2,h3,h4,h5,h6,p,ul li,ol li{
    color:#2c2c2c;
  }


  .bd-content>table>thead>tr>th {
    color: $black;

  }

  .blockquote, .blockquote p, .card p{
    color: rgba($white,0.8);
  }
  .bd-example {
    background: linear-gradient(#1e1e2f,#1e1e24);

  }

  .navbar {
    border-top: none;

    .navbar-nav .nav-link {
      color: rgba(255,255,255,.8) !important;
    }
  }

  .bd-example {

    .btn{
      margin: 4px 0;
    }
    .btn .badge {
      display: inline-block;
    }

    .tim-icons{
      color: $white;
    }

    .popover .popover-header {
    color: hsla(0,0%,71%,.6);
  }


    .popover-body {
      p {
        color: $gray-900;
      }
    }

    &.tooltip-demo p{
      color: rgba($white,.8);
    }
  }

  .card.card-body,
  .card .card-body {
    color: hsla(0,0%,100%,.8);
  }

  label,
  .form-check {
    color: hsla(0,0%,100%,.8);
  }

  .form-check + .btn {
    margin-top: 20px;
  }

  .bd-example,
  table {
    thead th {
      color: hsla(0,0%,100%,.8);
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      color: hsla(0,0%,100%,.8);
    }

    .datepicker{
      thead th, table thead th,.tim-icons{
        color: $primary;
      }

    }

    .picker-switch .tim-icons{
      color: $primary;
    }
  }

  .footer {
    .container-fluid > nav {
      display: inline-block;
    }
  }
}
// .modal.show .modal-dialog {
//   -webkit-transform: translate(0,30%);
//   transform: translate(0,30%);
// }

code {
  color: $pink;
}

@media screen and (max-width: 991px){
    .profile-photo .profile-photo-small{
        margin-left: -2px;
    }

    .button-dropdown{
        display: none;
    }

    #searchModal .modal-dialog{
      margin: 20px;
    }

    #minimizeSidebar{
        display: none;
    }

}


@media screen and (max-width: 768px){

    .landing-page .section-story-overview .image-container:nth-child(2){
        margin-left: 0;
        margin-bottom: 30px;
    }

}

@media screen and (max-width: 576px){
    .page-header{
        .container h6.category-absolute{
            width: 90%;
        }
    }

    .form-horizontal .col-form-label, .form-horizontal .label-on-right{
      text-align: inherit;
      padding-top: 0;
      code{
        padding: 0 10px;
      }
    }

}
