@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }

  50% {
    background-position: 91% 100%
  }

  100% {
    background-position: 10% 0%
  }
}

@-moz-keyframes Animation {
  0% {
    background-position: 10% 0%
  }

  50% {
    background-position: 91% 100%
  }

  100% {
    background-position: 10% 0%
  }
}

@keyframes Animation {
  0% {
    background-position: 10% 0%
  }

  50% {
    background-position: 91% 100%
  }

  100% {
    background-position: 10% 0%
  }
}

@-moz-keyframes autofill {
  100% {
    background: transparent !important;
    color: inherit !important;
  }
}
.login-page.register-page {
  margin: 40px 0;
  height: 100%;
}